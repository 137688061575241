<template>
	<v-card
		elevation="0"
		:loading="loadingData"
		class="ma-0 pa-0"
		@keyup.enter="saveProfileDetails"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getBasicInfo" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>
				
				<v-col cols="auto" class="text-right py-0 mb-1">
					<v-tooltip bottom nudge-bottom="-15">
						<template v-slot:activator="{ on: onSave }">
							<v-icon
								size="30"
								:color="hoverSave? 'blue darken-1' : 'blue darken-2'"
								v-on="onSave"
								@mouseover="hoverSave = !hoverSave"
								@mouseleave="hoverSave = !hoverSave"
								@click="saveProfileDetails()"
							>mdi-content-save</v-icon>
						</template>
						<small>Save</small>
					</v-tooltip>
					
					<v-tooltip bottom nudge-bottom="-15">
						<template v-slot:activator="{ on: onRefresh }">
							<v-icon
								size="30"
								:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
								v-on="onRefresh"
								@mouseover="hoverRefresh = !hoverRefresh"
								@mouseleave="hoverRefresh = !hoverRefresh"
								@click="getBasicInfo"
							>mdi-refresh-circle</v-icon>
						</template>
						<small>Refresh</small>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-card-text>
			
		<v-card-text class="pa-0 ma-0">
		
			<v-form ref="formProfileDetails">
				<v-simple-table :height="userFormHeight - 40">
					<template v-slot:default>
				
						<v-row dense no-gutters class="pr-2" style="overflow: hidden;">
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									v-model="lastName"
									label="Last Name*"
									:readonly="verified"
									:rules="rulesLastName"
									validate-on-blur
									:error="lastNameError"
									:error-messages="lastNameErrorMsg"
									:loading="lastNameLoading"
									@focus="lastNameError = false; lastNameErrorMsg = '';"
									@keyup="uniqueName('lname')"
									@blur="uniqueName('lname'); lastName = trimName(lastName)"
									class="uppercase-input"
								></v-text-field>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									v-model="firstName"
									label="First Name*"
									:readonly="verified"
									:rules="rulesFirstName"
									validate-on-blur
									:error="firstNameError"
									:error-messages="firstNameErrorMsg"
									:loading="firstNameLoading"
									@focus="firstNameError = false; firstNameErrorMsg = '';"
									@keyup="uniqueName('fname');"
									@blur="uniqueName('fname'); firstName = trimName(firstName)"
									class="uppercase-input"
								></v-text-field>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									v-model="middleName"
									label="Middle Name"
									:readonly="verified"
									:rules="rulesMiddleName"
									validate-on-blur
									:error="middleNameError"
									:error-messages="middleNameErrorMsg"
									:loading="middleNameLoading"
									@focus="middleNameError = false; middleNameErrorMsg = '';"
									@keyup="uniqueName('mname');"
									@blur="uniqueName('mname'); middleName = trimName(middleName)"
									class="uppercase-input"
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									v-if="!newSuffixName"
									v-model="suffixName"
									clearable
									label="Suffix Name"
									:readonly="verified"
									:items="suffixNameOption"
									item-text="val"
									item-value="val"
									:error="suffixNameError"
									:error-messages="suffixNameErrorMsg"
									:rules="rulesSuffixName"
									validate-on-blur
									:loading="suffixNameLoading"
									@blur="uniqueName('sname')"
								>
									<template v-slot:append-outer v-if="!verified">
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newSuffixName = !newSuffixName; oldSuffixName = suffixName; suffixName = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									v-if="newSuffixName"
									v-model="suffixName"
									label="Suffix Name"
									hint="Specify here"
									:error="suffixNameError"
									:error-messages="suffixNameErrorMsg"
									:rules="rulesSuffixName"
									validate-on-blur
									:loading="suffixNameLoading"
									@focus="suffixNameError = false; suffixNameErrorMsg = '';"
									@keyup="uniqueName('sname')"
									@blur="uniqueName('sname'); suffixName = trimName(suffixName); optionExist('sname')"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newSuffixName = !newSuffixName; suffixName = oldSuffixName">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									v-model="birthDate"
									label="Date of Birth*"
									:type="dobType"
									hint="MM/DD/YYYY"
									persistent-hint
									validate-on-blur
									:rules= "rulesDOB"
									@focus="dobType = 'date'"
									@blur="dobType = (isValidDate(birthDate))? 'date' : 'text'"
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-autocomplete
									v-if="!newbirthPlace"
									v-model="birthPlace"
									clearable
									hint="City, Province"
									label="Place of Birth*"
									persistent-hint
									:rules= "rulesBirthPlace"
									validate-on-blur
									:items="birthPlaceOption"
									item-text="cityAndProvince"
									item-value="cityAndProvince"
									:loading="isLoading"
									:search-input.sync="searchPOB"
									:no-data-text="birthPlaceNoData"
									@focus="birthPlaceOn = true;"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newbirthPlace = !newbirthPlace; oldbirthPlace = birthPlace; birthPlace = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-autocomplete>
								
								<v-text-field
									label="Place of Birth*"
									v-model="birthPlace"
									v-if="newbirthPlace"
									hint="City, Province"
									persistent-hint
									:rules= "rulesBirthPlace"
									validate-on-blur
									@blur="birthPlace = trimText(birthPlace);"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newbirthPlace = !newbirthPlace; birthPlace = oldbirthPlace;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Gender*"
									v-model="gender"
									:rules= "rulesGender"
									validate-on-blur
									:items="genderOption"
									item-text="description"
									item-value="code"
								></v-select>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Civil Status*"
									v-model="civilStatus"
									:rules= "rulesCivilStatus"
									validate-on-blur
									:items="civilStatusOption"
									item-text="description"
									item-value="code"
								></v-select>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Nationality*"
									v-model="nationality"
									v-if="!newNationality"
									:rules= "rulesNationality"
									validate-on-blur
									:items="nationalityOption"
									item-text="val"
									item-value="val"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newNationality = !newNationality; oldNationality = nationality; nationality = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									label="Nationality*"
									v-model="nationality"
									v-if="newNationality"
									hint="Specify here"
									persistent-hint
									:rules= "rulesNationality"
									validate-on-blur
									@blur="nationality = trimName(nationality);"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newNationality = !newNationality; nationality = oldNationality">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Religion*"
									v-model="religion"
									v-if="!newReligion"
									:rules= "rulesReligion"
									validate-on-blur
									:items="religionOption"
									item-text="description"
									item-value="code"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newReligion = !newReligion; oldReligion = religion; religion = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									label="Religion*"
									v-model="religion"
									v-if="newReligion"
									hint="Specify here"
									persistent-hint
									:rules= "rulesReligion"
									validate-on-blur
									@blur="religion = trimName(religion)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newReligion = !newReligion; religion = oldReligion">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Height*"
									v-model="height"
									:rules= "rulesHeight"
									validate-on-blur
									:items="heightOption"
									item-text="val"
									item-value="key"
								></v-select>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Weight*"
									v-model="weight"
									:rules= "rulesWeight"
									validate-on-blur
									:items="weightOption"
									item-text="val"
									item-value="key"
								></v-select>
							</v-col>

							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Color of Eyes*"
									v-model="eyesColor"
									v-if="!newEyesColor"
									:rules= "rulesEyesColor"
									validate-on-blur
									:items="eyesColorOption"
									item-text="val"
									item-value="val"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newEyesColor = !newEyesColor; oldEyesColor = eyesColor; eyesColor = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									label="Color of Eyes*"
									v-model="eyesColor"
									v-if="newEyesColor"
									hint="Specify here"
									persistent-hint
									:rules= "rulesEyesColor"
									validate-on-blur
									@blur="eyesColor = trimName(eyesColor)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newEyesColor = !newEyesColor; eyesColor = oldEyesColor;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Color of Hair*"
									v-model="hairColor"
									v-if="!newHairColor"
									:rules= "rulesHairColor"
									validate-on-blur
									:items="hairColorOption"
									item-text="val"
									item-value="val"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newHairColor = !newHairColor; oldHairColor = hairColor; hairColor = '';">mdi-plus</v-icon>
											</template>
											<small>Others</small>
										</v-tooltip>
									</template>
								</v-select>
								
								<v-text-field
									label="Color of Hair*"
									v-model="hairColor"
									v-if="newHairColor"
									hint="Specify here"
									persistent-hint
									:rules= "rulesHairColor"
									validate-on-blur
									@blur="hairColor = trimName(hairColor)"
									class="uppercase-input"
								>
									<template v-slot:append-outer>
										<v-tooltip bottom>
											<template v-slot:activator="{ on: onToolTip }">
												<v-icon left v-on="onToolTip" @click="newHairColor = !newHairColor; hairColor = oldHairColor;">mdi-format-list-bulleted</v-icon>
											</template>
											<small>Select</small>
										</v-tooltip>
									</template>
								</v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Shoe Size*"
									v-model="shoeSize"
									:rules= "rulesShoeSize"
									validate-on-blur
									:items="shoeSizeOption"
									item-text="val"
									item-value="key"
								></v-select>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-select
									label="Coverall Size*"
									v-model="coverAllSize"
									:rules= "rulesCoverAllSize"
									validate-on-blur
									:items="coverAllOption"
									item-text="description"
									item-value="code"
								></v-select>
							</v-col>
							
							<v-col cols="12">
								<v-icon medium class="pb-2" color="blue darken-2">mdi-card-account-details</v-icon> 
								<span class="title">&nbsp;GOVERNMENT ID</span>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									label="SSS ID Number"
									v-model="SSS"
									v-mask= "maskSSS"
									:rules= "rulesSSS"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									label="PAG-IBIG (HDMF) ID Number"
									v-model="HDMF"
									v-mask= "maskHDMF"
									:rules= "rulesHDMF"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									label="TIN ID Number"
									v-model="TIN"
									v-mask= "maskTIN"
									:rules= "rulesTIN"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12" xl="3" lg="3" md="3" sm="6">
								<v-text-field
									label="PhilHealth ID Number"
									v-model="philHealth"
									v-mask= "maskPhilHealth"
									:rules= "rulesPhilHealth"
									validate-on-blur
								></v-text-field>
							</v-col>
							
							<v-col cols="12">
								<small>
									Note: Field with asterisk(*) is required
									<font v-if="verified"> and name is disabled for editing.</font>
									<font v-else>.</font>
								</small>
							</v-col>
							
						</v-row>
						
					</template>
				</v-simple-table>
			</v-form>
			
			<v-dialog
				v-model="loadingUpdate"
				max-width="355"
				persistent
			>
				<v-card color="primary" dark>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
				
		</v-card-text>
		
	</v-card>	
	
</template>

<script>
import { CLEAR_ALERT, STORE_INFO } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutName = null;
var timeoutPOB = null;
var timeoutData = null;

export default {
	directives: {mask},
	data () {
		return {
			newSuffixName		: false,
			newbirthPlace		: false,
			newNationality		: false,
			newReligion			: false,
			newHairColor		: false,
			newEyesColor		: false,
			nameUnique			: true,
			
			oldSuffixName		: '',
			oldbirthPlace		: '',
			oldNationality		: '',
			oldReligion			: '',
			oldHairColor		: '',
			oldEyesColor		: '',
			
			hoverRefresh		: false,
			hoverSave			: false,
			
			//data variables
			userid				: '',
			lastName			: '',
			firstName			: '',
			middleName			: '',
			suffixName			: '',
			birthDate			: '',
			birthPlace			: '',
			gender				: '',
			civilStatus			: '',
			nationality			: '',
			religion			: '',
			height				: '',
			weight				: '',
			eyesColor			: '',
			hairColor			: '',
			shoeSize			: '',
			coverAllSize		: '',
			SSS					: '',
			HDMF				: '',
			TIN					: '',
			philHealth			: '',
			verified			: false,
			
			//name valdiation
			lastNameLoading		: false,
			lastNameError		: false,
			lastNameErrorMsg	: "",
			firstNameLoading	: false,
			firstNameError		: false,
			firstNameErrorMsg	: "",
			middleNameLoading	: false,
			middleNameError		: false,
			middleNameErrorMsg	: "",
			suffixNameLoading	: false,
			suffixNameError		: false,
			suffixNameErrorMsg	: "",
			
			//dropdown options
			suffixNameOption	: [],
			birthPlaceOption	: [],
			genderOption		: [],
			civilStatusOption	: [],
			nationalityOption	: [],
			religionOption		: [],
			heightOption		: [],
			weightOption		: [],
			eyesColorOption		: [],
			hairColorOption		: [],
			shoeSizeOption		: [],
			coverAllOption		: [],
			
			//autocomplete
			searchPOB			: null,
			birthPlaceOn		: false,
			birthPlaceNoData	: 'Searching...',
			
			//loading status
			isLoaded			: false,
			isLoading			: false,
			loadingData			: false,
			loadingUpdate		: false,
			isLoadedOptions		: false,
			
			//mask
			maskSSS				: '##-#######-#',
			maskHDMF			: '####-####-####',
			maskTIN				: '###-###-###',
			maskPhilHealth		: '##-#########-#',
			
			dobType				: 'text',
			
			//rules
			rulesLastName: [
				v => !!v || 'Last Name is required',
				v => (v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (v && this.stringLength(v) <= 30) || 'Must be less than 30 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesFirstName: [
				v => !!v || 'First Name is required',
				v => (v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesMiddleName: [
				v => (this.stringLength(v) == 0 || this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (this.stringLength(v) == 0 || this.stringLength(v) <= 30) || 'Must be less than 30 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesSuffixName: [
				v => (this.stringLength(v) == 0 || this.stringLength(v) <= 5) || 'Must be less than 5 characters',
			],
			rulesDOB: [
				v => !!v || 'Date of Birth is required',
				v => v < (this.serverDate.split('-')[0] - 18)+this.serverDate.slice(4, 10) || 'Must be 18 years old and above',
			],
			rulesBirthPlace: [
				v => !!v || 'Place of Birth is required',
				v => (v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ.,-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesGender: [
				v => !!v || 'Gender is required',
			],
			rulesCivilStatus: [
				v => !!v || 'Civil Status is required',
			],
			rulesNationality: [
				v => !!v || 'Nationality is required',
				v => (v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesReligion: [
				v => !!v || 'Religion is required',
				v => (v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesHeight: [
				v => !!v || 'Height is required',
			],
			rulesWeight: [
				v => !!v || 'Weight is required',
			],
			rulesEyesColor: [
				v => !!v || 'Eyes Color is required',
				v => (v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesHairColor: [
				v => !!v || 'Hair Color is required',
				v => (v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesShoeSize: [
				v => !!v || 'Shoe Size is required',
			],
			rulesCoverAllSize: [
				v => !!v || 'Coverall Size is required',
			],
			rulesSSS: [
				v => (this.stringLength(v) == 0 || this.stringLength(v) == 12) || 'Incorrect SSS Number',
			],
			rulesHDMF: [
				v => (this.stringLength(v) == 0 || this.stringLength(v) == 14) || 'Incorrect PAG-IBIG Number',
			],
			rulesTIN: [
				v => (this.stringLength(v) == 0 || this.stringLength(v) == 11) || 'Incorrect TIN Number',
			],
			rulesPhilHealth: [
				v => (this.stringLength(v) == 0 || this.stringLength(v) == 14) || 'Incorrect PhilHealth Number',
			],
		}
	},
	methods: {
		getBasicInfo () {
			if(this.loadingData && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();
			
			this.$store.dispatch(CLEAR_ALERT);
			this.loadingData = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
			
			this.$http.get('profile', { cancelToken: source.token })
			.then((resp) => {
				this.lastName = resp.data.LNAME;
				this.firstName = resp.data.FNAME;
			//	this.firstName = (resp.data.SUFFIX != '')? this.firstName = resp.data.FNAME.slice( 0, resp.data.FNAME.length - resp.data.SUFFIX.length).trim() : this.firstName = resp.data.FNAME;
				this.middleName = resp.data.MNAME;
				
				if(resp.data.SUFFIX){
					this.suffixNameOption.push(resp.data.SUFFIX);
					this.suffixName = resp.data.SUFFIX;
				}
				
				if(this.isValidDate(resp.data.DOB)){
					this.birthDate = resp.data.DOB;
					this.dobType = 'date';
				}
				
				if(resp.data.POB){
					this.birthPlaceOption.push(resp.data.POB);
					this.birthPlace = resp.data.POB;
				}
				
				if(resp.data.GENDER){
					this.genderOption.push(resp.data.GENDER);
					this.gender = resp.data.GENDER;
				}
				
				if(resp.data.CIVIL_STATUS){
					this.civilStatusOption.push(resp.data.CIVIL_STATUS);
					this.civilStatus = resp.data.CIVIL_STATUS;
				}
				
				if(resp.data.NATIONALITY){
					this.nationalityOption.push( { val: resp.data.NATIONALITY} );
					this.nationality = resp.data.NATIONALITY;
				}
				
				if(resp.data.RELIGION){
					this.religionOption.push(resp.data.RELIGION);
					this.religion = resp.data.RELIGION;
				}
				
				if(resp.data.HEIGHT)this.height = resp.data.HEIGHT;
				for( var i = 61; i <= 83; i++ ){
					var cm = (i*2.54);
					var feet = Math.trunc(i / 12);
					var inch = i - (feet*12);
					if( inch < 10 )inch = "0"+inch;
					this.heightOption.push({ key: cm.toFixed(2),  val: feet+"' "+inch+" ("+cm.toFixed(2)+" cm)" });
				}
				
				if(resp.data.WEIGHT)this.weight = resp.data.WEIGHT;
				for( var x = 100; x <= 219; x++ ){
					var kg = x/2.20
					this.weightOption.push({ key: kg.toFixed(2),  val: x+' lbs ('+kg.toFixed(2)+' kg)' });
				}
				
				if(resp.data.EYECOLOR){
					this.eyesColorOption.push(resp.data.EYECOLOR);
					this.eyesColor = resp.data.EYECOLOR;
				}
				
				if(resp.data.HAIRCOLOR){
					this.hairColorOption.push(resp.data.HAIRCOLOR);
					this.hairColor = resp.data.HAIRCOLOR;
				}
				
				this.shoeSize = resp.data.SHOE_SIZE - 0;
				for( var y = 5; y <= 15; y++ ){
					this.shoeSizeOption.push({ key: y, val: y+' in' });
				}
				
				if(resp.data.COVERALL_SIZE){
					this.coverAllOption.push(resp.data.COVERALL_SIZE);
					this.coverAllSize = resp.data.COVERALL_SIZE;
				}
				
				if(resp.data.SSS){
					const sss = resp.data.SSS.replace(/[^\d]/g, "");
					this.SSS = (sss != '')? sss.substr(0, 2)+'-'+sss.substr(2, 7)+'-'+sss.substr(9, 1) : '';
				}
				
				if(resp.data.PAG_IBIG){
					const hdmf = resp.data.PAG_IBIG.replace(/[^\d]/g, "");
					this.HDMF = (hdmf != '')? hdmf.substr(0, 4)+'-'+hdmf.substr(4, 4)+'-'+hdmf.substr(8, 4) : '';
				}
				
				if(resp.data.TIN){
					const tin = resp.data.TIN.replace(/[^\d]/g, "");
					this.TIN = (tin != '')? tin.substr(0, 3)+'-'+tin.substr(3, 3)+'-'+tin.substr(6, 3) : '';
				}
				
				if(resp.data.PHILHEALTH){
					const philHealth = resp.data.PHILHEALTH.replace(/[^\d]/g, "");
					this.philHealth = (philHealth != '')? philHealth.substr(0, 2)+'-'+philHealth.substr(2, 9)+'-'+philHealth.substr(11, 1) : '';
				}
				
				this.userid = resp.data.USERID;
				if( resp.data.VERIFIED_BY > 0 )this.verified = true;
				
				//reset all validation error
				this.$refs.formProfileDetails.resetValidation();
				this.lastNameError = this.firstNameError = this.middleNameError = this.suffixNameError = false;
				this.lastNameErrorMsg = this.firstNameErrorMsg = this.middleNameErrorMsg = this.suffixNameErrorMsg = "";
				
				this.isLoaded = true;
				this.dropdown();
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getBasicInfo();
				}, 60000);
			})
			.finally(() => {
				this.loadingData = false;
			});
		},
		saveProfileDetails(){
			if(this.$refs.formProfileDetails.validate() && !(this.lastNameError || this.firstNameError || this.middleNameError || this.suffixNameError) ){
				this.$store.dispatch(CLEAR_ALERT);
				this.loadingUpdate = true;
				
				this.$http.put('profile', {
					newSuffixName	: this.newSuffixName,
					newbirthPlace	: this.newbirthPlace,
					newNationality	: this.newNationality,
					newReligion		: this.newReligion,
					newHairColor	: this.newHairColor,
					newEyesColor	: this.newEyesColor,
			
					LNAME			: this.trimText(this.lastName),
					FNAME			: this.trimText(this.firstName),
					MNAME			: this.trimText(this.middleName),
					SUFFIX			: this.trimText(this.suffixName),
					DOB				: this.birthDate,
					POB				: this.birthPlace,
					GENDER			: this.gender,
					CIVIL_STATUS	: this.civilStatus,
					NATIONALITY		: this.nationality.toUpperCase().trim(),
					RELIGION		: this.religion.toUpperCase().trim(),
					HEIGHT			: this.height,
					WEIGHT			: this.weight,
					EYECOLOR		: this.eyesColor.toUpperCase().trim(),
					HAIRCOLOR		: this.hairColor.toUpperCase().trim(),
					SHOE_SIZE		: this.shoeSize,
					COVERALL_SIZE	: this.coverAllSize,
					SSS				: this.SSS,
					PAG_IBIG		: this.HDMF,
					TIN				: this.TIN,
					PHILHEALTH		: this.philHealth
				})
				.then(() => {
					
					var fullName = this.trimText(this.lastName)+', '+this.trimText(this.firstName);
					
					if( this.trimText(this.suffixName) != '' )fullName = fullName+' '+this.trimText(this.suffixName);
					if( this.trimText(this.middleName) != '' )fullName = fullName+' '+this.trimText(this.middleName).substr(0,1)+'.';
					
					const rank = this.rank;
					const name = fullName;
					
					this.$store.dispatch(STORE_INFO, {rank, name} );
				})
				.finally(() => {
					this.loadingUpdate = false;
				});
			}
		},
		dropdown(){
			if(!this.isLoadedOptions){
				this.$http.get('dropdown/profileDropdowns').then((resp) => {
					this.suffixNameOption = resp.data.suffixName;
					if(this.suffixName != '')this.suffixNameOption.unshift(this.suffixName);
					
					this.genderOption = resp.data.gender;
					if(this.gender != '')this.genderOption.unshift(this.gender);
					
					this.civilStatusOption = resp.data.civilStatus;
					if(this.civilStatus != '')this.civilStatusOption.unshift(this.civilStatus);
					
					this.nationalityOption = resp.data.nationality;
					if(this.nationality != '')this.nationalityOption.unshift(this.nationality);
					
					this.religionOption = resp.data.religion;
					if(this.religion != '')this.religionOption.unshift(this.religion);
					
					this.eyesColorOption = resp.data.eyesColor;
					if(this.eyesColor != '')this.eyesColorOption.unshift(this.eyesColor);
					
					this.hairColorOption = resp.data.hairColor;
					if(this.hairColor != '')this.hairColorOption.unshift(this.hairColor);
					
					this.coverAllOption = resp.data.coverAllSize;
					if(this.coverAllSize != '')this.coverAllOption.unshift(this.coverAllSize);
				})
				.then(() => {
					this.isLoadedOptions = true;
				})
				.catch(() => {
					this.dropdown();
				});
			}
		},
		uniqueName(el){
			if( this.verified )return
			
			if( this.lastName.length >= 2 && this.firstName.length >= 2
				&& !/[^a-zA-ZñÑ-\s]+/.test(this.lastName) && !/[^a-zA-ZñÑ-\s]+/.test(this.firstName) && !/[^a-zA-ZñÑ-\s]+/.test(this.middleName)
			){
				
				clearTimeout(timeoutName);
			
				timeoutName = setTimeout(() => {
					if( el == 'lname'){
						this.lastNameLoading = true;
					}else if( el == 'fname'){
						this.firstNameLoading = true;
					}else if( el == 'mname'){
						this.middleNameLoading = true;
					}else{
						this.suffixNameLoading = true;
					}
					
					this.lastNameError = this.firstNameError = this.middleNameError = this.suffixNameError = false;
					this.lastNameErrorMsg = this.firstNameErrorMsg = this.middleNameErrorMsg = this.suffixNameErrorMsg = "";
					this.nameUnique = true;
					
					this.$http.get('register/uniqueName',
						{ params:{
							lname	: (this.lastName)? this.filterName(this.lastName) : '',
							fname	: (this.firstName)? this.filterName(this.firstName) : '',
							mname	: (this.middleName)? this.filterName(this.middleName) : '',
							sname	: (this.suffixName)? this.filterName(this.suffixName) : '',
							userid	: this.userid
						}
					})
					.then((response) => {
						if( response.data.count != 0 ){
							if( el == 'lname'){
								this.lastNameError = true;
								this.lastNameErrorMsg = "Name already exists";
							}else if( el == 'fname'){
								this.firstNameError = true;
								this.firstNameErrorMsg = "Name already exists";
							}else if( el == 'mname'){
								this.middleNameError = true;
								this.middleNameErrorMsg = "Name already exists";
							}else{
								this.suffixNameError = true;
								this.suffixNameErrorMsg = "Name already exists";
							}
							this.nameUnique = false;
						}
					})
					.catch(() => {
						if( el == 'lname'){
							this.lastNameError = true;
							this.lastNameErrorMsg = "Error, while checking";
						}else if( el == 'fname'){
							this.firstNameError = true;
							this.firstNameErrorMsg = "Error, while checking";
						}else if( el == 'mname'){
							this.middleNameError = true;
							this.middleNameErrorMsg = "Error, while checking";
						}else{
							this.suffixNameError = true;
							this.suffixNameErrorMsg = "Error, while checking";
						}
					})
					.finally(() =>{
						this.lastNameLoading = this.firstNameLoading = this.middleNameLoading = this.suffixNameLoading = false;
					});
				}, 500);
			}
		},
		optionExist (field){
			if( field == 'sname' && this.suffixNameOption.some(el => el.val === this.suffixName) )this.newSuffixName = false;
		},
		filterName(text){
			return (this.stringLength(text))? text.replace(/[^a-zA-ZñÑ-\s]+/g, '').replace('ñ', 'Ñ').replace(/ +/g, ' ').replace(/-+/g, '-').replace(/^-/g, '').toUpperCase().trimStart() : '';
		},
		trimName(text){
			return (this.stringLength(text))? text.replace(/ +/g, ' ').replace(/-+/g, '-').replace(/^-/g, '').toUpperCase().trimStart().trim() : '';
		},
		trimText(text){
			return (this.stringLength(text))? text.replace(/ +/g, ' ').replace(/-+/g, '-').replace(/^-/g, '').replace(/\.+/g, '.').replace(/^\./g, '').toUpperCase().trimStart().trim() : '';
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		isValidDate(dateString) {
			if(dateString == null)return false;
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	watch: {
		searchPOB (val) {
			if(!this.birthPlaceOn) return
			
			clearTimeout(timeoutPOB);
			
			timeoutPOB = setTimeout(() => {
				this.isLoading = true;
				this.birthPlaceNoData = "Searching...";
				
				this.$http.get('autocomplete/citiesAndProvinces', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.birthPlaceOption = res.data;
				})
				.finally(() => {
					if( this.birthPlaceOption.length == 0 ){
						this.birthPlaceNoData = "No match found";
					}
					this.isLoading = false;
				});
			}, 500);
		},
	},
	computed:{
		...mapGetters(['userFormHeight', 'serverDate', 'errorOn', 'errorType', 'errorMessage', 'rank', 'fname'])
	},
	created () {
		this.getBasicInfo();
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutName);
		clearTimeout(timeoutPOB);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>